// strip clutter from input and explode into arrays
// extract rawconfig (first line) and rawdata (the rest)
export const readInput = (input) => {
  const lines = input.split("\n");
  const rawdata = lines.reduce((result, line) => {
    let exploded = line.split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    if (line.length < 10) {
      // empty line
    } else {
      // remove double escaped quotes
      // also trim() to remove whitespace around words
      const clean = exploded.map((value) => {
        return value.replaceAll(/[\"]+/g, "").trim();
      });
      result.push(clean);
    }
    return result;
  }, []);
  const rawconfig = rawdata.shift(); // extract first line
  return [rawconfig, rawdata];
};

// extract mapping from rawconfig
export const readMapping = (rawconfig, providerName) => {
  const map = {};
  for (let index = 0; index < rawconfig.length; index++) {
    const fieldname = rawconfig[index];
    if (providerName === "paypal") {
      // paypal transactions
      if (fieldname === "Dato") map.created = index;
      if (fieldname === "Brutto") map.gross = index;
      if (fieldname === "Netto") map.net = index;
      if (fieldname === "Gebyr") map.fee = index;
      if (fieldname === "Beskrivelse") map.type = index;
      if (fieldname === "Faktura-ID") map.invoice = index;
      if (fieldname === "Saldo") map.balance = index;
    } else if (providerName === "paypal-summary") {
      // paypal summary transactions
      if (fieldname === "Dato") map.created = index;
      if (fieldname === "Brutto") map.gross = index;
      if (fieldname === "Netto") map.net = index;
      if (fieldname === "Gebyr") map.fee = index;
      if (fieldname === "Type") map.type = index;
      if (fieldname === "Bedrifts-/mottakernavn") map.invoice = index;
    } else {
      // stripe transactions or payouts
      if (fieldname === "created" || fieldname === "effective_at")
        map.created = index;
      if (fieldname === "gross") map.gross = index;
      if (fieldname === "fee") map.fee = index;
      if (fieldname === "reporting_category") map.type = index;
      if (fieldname === "description") map.desc = index;
    }
  }
  return map;
};

// extract vital dataset from lines
export const extractData = (mapping, rawdata) => {
  const result = rawdata.map((line) => {
    let description = line[mapping.desc];
    if (!description) {
      description = line[mapping.invoice]
        ? "#" + line[mapping.invoice] + line[mapping.type]
        : line[mapping.type];
    }
    const data = {
      created: line[mapping.created],
      type: line[mapping.type],
      gross: line[mapping.gross],
      net: line[mapping.net],
      fee: line[mapping.fee],
      desc: description,
      balance: line[mapping.balance],
    };
    return data;
  });
  return result;
};

// format dataset
export const formatData = (dataset, forceNegativeSum) => {
  const allSumsNegative = forceNegativeSum || false; // some input data may be missing the "-". default to false
  const formatted = [];
  const fees = {
    transactions: 0,
    security: 0,
  };

  if (dataset[0].created.indexOf(".") != -1) {
    // date is using "." and not "/" as separator (paypal-summary)
    dataset.forEach((item) => {
      const tmp = item.created.split(".");
      item.created =
        tmp[1].replace(/^0/, "") +
        "/" +
        tmp[0].replace(/^0/, "") +
        "/" +
        tmp[2];
    });
  }

  dataset.forEach((line, lineIndex) => {
    // format date
    const date = new Date(line.created);
    let day = date.getDate();
    day = day < 10 ? "0" + day : "" + day;
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : "" + month;
    const year = "" + date.getFullYear();
    const dateString = year + month + day;
    // format sum
    let sumprefix = isNaN(line.gross.charAt(0)) ? "-" : "+";
    if (allSumsNegative) {
      sumprefix = "-";
    }
    let sum = line.gross;
    sum = sum.replace(/[-−\.,\s]+/g, ""); // NOTE! There are 2 different "-" characters in this regex
    const prepadding = new Array(14 - sum.length).join(" ");
    const sumString = sumprefix + prepadding + sum + "000"; // we have no sum-identificator, just add 000
    // group dates by day in nested arrays ((array) dataset = (array) days = (array) transactions)
    if (typeof formatted[dateString] === "undefined") {
      // first transaction this day. create the array
      formatted[dateString] = [];
    }

    // improve description and make it exactly 20 chars
    let description = line.desc.replace("Order ", "");
    description = description.replace(" by ", " ");
    description = description.replace("REFUND FOR CHARGE (", "");
    description =
      description.length < 20
        ? description + new Array(21 - description.length).join(" ") // padd to 20 chars
        : description.substring(0, 20); // max 20 chars

    // generate a unique transactionId
    const uniqueId = "" + Date.now() + lineIndex;
    const id = uniqueId.substring(uniqueId.length - 11, uniqueId.length);
    const transactionId = new Array(23 - uniqueId.length).join("0") + uniqueId;

    // aggregate fees and print normal transactions
    if (line.type.toUpperCase() === "FEE") {
      // 3D-secure / Radar fee
      fees.security = fees.security + parseInt(sum);
    } else {
      // NORMAL TRANSACTIONS

      // format fee
      let fee = line.fee;
      fee = fee.replace(/[-−\.,\s]+/g, "");
      fee = parseInt(fee);
      fees.transactions = fees.transactions + fee;

      // format net
      let net = line.net;
      if (net) {
        net = net.replace(/[-−\.,\s]+/g, "");
        net = parseInt(net);
      }

      // format balance
      let balance = line.balance;
      if (balance) {
        balance = balance.replace(/[-−\.,\s]+/g, "");
        balance = parseInt(balance);
      }

      // print transaction
      formatted[dateString].push(
        // formatted transaction
        {
          id: "T-00000000000",
          date: dateString + dateString,
          type:
            line.type.length < 10
              ? line.type.toUpperCase() +
                new Array(10 - line.type.length).join(" ") // padded to 9 chars
              : line.type.substring(0, 9).toUpperCase(),
          transaction: transactionId,
          sum: sumString,
          desc: description,
          isNegative: sumprefix === "-",
          gross: sum,
          net: net,
          balance: balance,
        }
      );
    }

    if (!forceNegativeSum && dataset.length === lineIndex + 1) {
      // this was the last line. print aggregated fees
      if (fees.transactions) {
        fees.transactions = "" + fees.transactions; // convert to string
        const transUniqueId = "" + parseInt(transactionId) + 1;
        const transPrepadding = new Array(14 - fees.transactions.length).join(
          " "
        );
        const transSumString =
          "-" + transPrepadding + fees.transactions + "000";

        // push aggregated transactions
        formatted[dateString].push({
          id: "T-00000000000",
          date: dateString + dateString,
          type: "FEE" + new Array(7).join(" "), // padded to 9 chars
          transaction:
            new Array(23 - transUniqueId.length).join("0") + transUniqueId,
          sum: transSumString,
          desc: "TRANSACTION FEES" + new Array(5).join(" "),
          isNegative: true,
          gross: fees.transactions,
        });
      }

      if (fees.security) {
        fees.security = "" + fees.security;
        const secUniqueId = "" + (parseInt(transactionId) + 2);
        const secPrepadding = new Array(14 - fees.security.length).join(" ");
        const secSumString = "-" + secPrepadding + fees.security + "000";
        // push aggregated fees
        formatted[dateString].push({
          id: "T-00000000000",
          date: dateString + dateString,
          type: "FEE" + new Array(7).join(" "), // padded to 9 chars
          transaction:
            new Array(23 - secUniqueId.length).join("0") + secUniqueId,
          sum: secSumString,
          desc: "RADAR 3D SECURE FEES",
          isNegative: true,
          gross: fees.security,
        });
      }
    }
  });
  return formatted;
};
