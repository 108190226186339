import { useState, useRef } from "react";
import { readInput, readMapping, extractData, formatData } from "./helpers";

export default function CSVLoader({
  label,
  onLoadComplete,
  onShowPreview,
  forceNegativeSum,
  provider,
}) {
  const inputfile = useRef();
  const [text, setText] = useState();

  const load = () => {
    const fr = new FileReader();
    fr.onload = function () {
      setText(fr.result);
      getFormattedData(fr.result);
    };
    fr.readAsText(inputfile.current.files[0]);
  };

  // main function to read and format one csv file
  const getFormattedData = (input) => {
    const [rawconfig, rawdata] = readInput(input);
    const mapping = readMapping(rawconfig, provider);
    const dataset = extractData(mapping, rawdata);
    const formatted = formatData(dataset, forceNegativeSum);
    onLoadComplete(formatted);
  };

  return (
    <section>
      <label>{label}</label>
      {!text ? (
        <input type="file" ref={inputfile} onChange={load} />
      ) : (
        <output className="message message--success">
          <span>File finished loading!</span>
          <button
            type="button"
            className="button button--action"
            onClick={() => {
              onShowPreview(text);
            }}
          >
            Inspect »
          </button>
        </output>
      )}
    </section>
  );
}
