import "./styles.scss";
import { useState } from "react";
import CSVConverter from "./CSVConverter";
import TelepayConverter from "./TelepayConverter";
import PreviewOutput from "./PreviewOutput";

export default function App() {
  const [mode, setMode] = useState();
  const [previewText, setPreviewText] = useState("No file selected");

  const restart = () => {
    setMode(null);
    setPreviewText("No file selected");
  };

  if (!mode) {
    return (
      <div className="App">
        <header>
          <h1>CSV ➡ Telepay converter 🪄</h1>
        </header>
        <main className="column">
          <nav className="main-menu">
            <label>Select CSV origin</label>
            <button
              type="button"
              className="button button--main button--start-nav"
              onClick={() => setMode("paypal")}
            >
              Paypal kontoutskrift
            </button>
            <button
              type="button"
              className="button button--main button--start-nav"
              onClick={() => setMode("paypal-summary")}
            >
              Paypal sammendrag
            </button>
            <button
              type="button"
              className="button button--main button--start-nav"
              onClick={() => setMode("stripe")}
            >
              Stripe
            </button>
            <button
              type="button"
              className="button button--main button--start-nav"
              onClick={() => setMode("telepay")}
            >
              Telepay
            </button>
          </nav>
        </main>
      </div>
    );
  }
  return (
    <div className="App">
      <header>
        <h1>CSV ➡ Telepay converter 🪄</h1>
        <div className="mode">
          <span className="ucfirst">{mode} mode</span>
          <button
            type="button"
            className="button button--action"
            onClick={restart}
          >
            Reset
          </button>
        </div>
      </header>
      {mode === "telepay" ? (
        <TelepayConverter mode={mode} setPreviewText={setPreviewText} />
      ) : (
        <CSVConverter mode={mode} setPreviewText={setPreviewText} />
      )}
      <PreviewOutput previewText={previewText} />
    </div>
  );
}
