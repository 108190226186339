import "./styles.scss";
import { useState, useEffect, useRef } from "react";

export default function TelepayConverter({mode, setPreviewText}) {

  const [transactionsData, setTransactionsData] = useState()
  const inputfile = useRef()
  const [text, setText] = useState()
  const [result, setResult] = useState()
  const [status, setStatus] = useState("idle")

  const previewResult = () => {
    setPreviewText(result)
  }

  useEffect(() => {
    // load result automatically when finished converting
    if (result) previewResult()
  }, [result])

  useEffect(() => {
    if (status === 'working') {
      convert()
    }
  }, [status])

  const load = () => {
      const fr=new FileReader()
      fr.onload=function(){
          setText(fr.result)
          getFormattedData(fr.result)
      }
      fr.readAsText(inputfile.current.files[0], 'iso-8859-15')
  }

  // main function to read and format one csv file
  const getFormattedData = (input) => {
      const maxStringLength = 97
      const lines = input.split("\n");
      const result = lines.map( (line) => {
        if (line.charAt(0) === "S") {
          return line.substring(0, 41) // max length for start of day
        }
        return line.substring(0,maxStringLength)
        }
      )
      setTransactionsData(result)
  }

  const convert = () => {
    if (!transactionsData) {
      setPreviewText(["No transactions found"])
      setStatus('idle')
      return null
    }

    // generate output
    let output = "";
    for (let line in transactionsData) {
      const text = transactionsData[line]
      output += text + "\n";
    }

    // show output in the app
    setResult(output);
    setStatus('done');
  }

  if (!mode) return null

  return (
      <main className="column">
        <section>
          <label>Load Telepay2 file</label>
          {!text ? (
              <input type="file" ref={inputfile} onChange={load} />
          ) : (
              <output className="message message--success">
                  <span>File finished loading!</span>
                  <button
                      type="button"
                      className="button button--action"
                      onClick={() => { setPreviewText(text) }}
                  >Inspect »</button>
              </output>
          )}
        </section>
        <section>
          <label>Convert</label>
          {status === "idle" &&
            <button className="button button--main" type="button" onClick={() => setStatus('working')}>
              Start
            </button>
          }
          {status === "working" &&
            <button className="button button--main button--disabled" type="button" disabled={true}>
              Working
            </button>
          }
          {status === "done" &&
          <>
            <h1>Finished converting!</h1>
            <button className="button" type="button" onClick={() => setStatus('working')}>
              Re-compile
            </button>
            <button className="button button--action" type="button" onClick={previewResult}>
              View output »
            </button>
            </>
          }
        </section>

      </main>
  );
}
