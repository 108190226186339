import "./styles.scss";
import { useState, useEffect } from "react";
import CSVLoader from "./CSVLoader";

export default function CSVConverter({ mode, setPreviewText }) {
  const [startBalance, setStartBalance] = useState();
  const [transactionsData, setTransactionsData] = useState();
  const [payoutsData, setPayoutsData] = useState();
  const [result, setResult] = useState();

  const [status, setStatus] = useState("idle");
  const [downloadUrl, setDownloadUrl] = useState(null);

  const onTransactionsLoaded = (data) => {
    setTransactionsData(data);
    if (mode === "paypal") {
      // read starting balance from first line
      for (const day in data) {
        for (const trans of data[day]) {
          const balance = trans.isNegative
            ? trans.balance + trans.net
            : trans.balance - trans.net;
          setStartBalance(balance);
          break;
        }
        break;
      }
    }
  };

  const previewResult = () => {
    setPreviewText(result);
  };

  useEffect(() => {
    // load result automatically when finished converting
    if (result) previewResult();
  }, [result]);

  useEffect(() => {
    if (status === "working") {
      convert();
    }
  }, [status]);

  const convert = () => {
    if (!transactionsData) {
      setPreviewText(["No transactions found"]);
      setStatus("idle");
      return null;
    }
    const formatted = transactionsData;

    // import payouts
    if (payoutsData && payoutsData.length > 1) {
      // merge payout csv into transactions
      for (const day in payoutsData) {
        if (typeof formatted[day] === "undefined") {
          formatted[day] = payoutsData[day];
        } else {
          formatted[day].push(...payoutsData[day]);
        }
      }
    }

    // generate startbalance for each day
    //const startbalances = [];
    let isFirstLine = true;
    let previousSum = 0;
    const startbalances = formatted.map((transactions, index) => {
      let sum = 0;
      if (isFirstLine) {
        sum = sum + parseInt(startBalance);
        isFirstLine = false;
      } else {
        sum = sum + parseInt(previousSum);
      }
      for (let trans of transactions) {
        if (trans.isNegative) {
          sum = sum - parseInt(trans.gross);
        } else {
          sum = sum + parseInt(trans.gross);
        }
      }
      previousSum = sum;
      return sum;
    });
    // generate output
    let output = "";
    for (const day in formatted) {
      output += "HH" + day + "\n";

      // add startbalance total for each transaction-day 13 numbers
      output += "S-00000000000"; // we have no account number
      const startsumString = "" + startbalances[day];
      const prepadding = new Array(14 - startsumString.length).join(" ");
      output += "+" + prepadding + startsumString;
      output += "+" + prepadding + startsumString;
      output += "\n";

      // add each transaction
      for (const trans of formatted[day]) {
        output +=
          "" +
          trans.id +
          trans.date +
          trans.type +
          trans.transaction +
          trans.sum +
          trans.desc +
          "\n";
      }
    }

    // show output in the app
    setResult(output);
    setStatus("done");

    // create a downloadable textfile
    let textFile = null;
    const makeTextFile = (text) => {
      var data = new Blob([text], { type: "text/plain" });

      // If we are replacing a previously generated file we need to
      // manually revoke the object URL to avoid memory leaks.
      if (textFile !== null) {
        window.URL.revokeObjectURL(textFile);
      }

      textFile = window.URL.createObjectURL(data);

      // returns a URL you can use as a href
      return textFile;
    };
    const downloadUrl = makeTextFile(output);
    setDownloadUrl(downloadUrl);
  };

  if (!mode) return null;

  return (
    <main className="column">
      <section>
        <label>Starting balance</label>
        <input
          className="startbalance"
          type="number"
          value={startBalance}
          onChange={(e) => setStartBalance(e.target.value)}
          disabled={mode == "paypal"}
          placeholder={mode == "paypal" ? "Transactions autofill" : null}
        />
      </section>
      <CSVLoader
        label="Transactions"
        onLoadComplete={onTransactionsLoaded}
        onShowPreview={setPreviewText}
        provider={mode}
      />
      <CSVLoader
        label="Payouts"
        forceNegativeSum={true}
        onLoadComplete={setPayoutsData}
        onShowPreview={setPreviewText}
        provider={mode}
      />

      <section>
        <label>Convert</label>
        {status === "idle" && (
          <button
            className="button button--main"
            type="button"
            onClick={() => setStatus("working")}
          >
            Start
          </button>
        )}
        {status === "working" && (
          <button
            className="button button--main button--disabled"
            type="button"
            disabled={true}
          >
            Working
          </button>
        )}
        {status === "done" && (
          <>
            <h1>Finished converting!</h1>
            {downloadUrl && (
              <p>
                <a
                  href={downloadUrl}
                  title="download the converted telepay"
                  download="telepay.txt"
                >
                  Download telepay.txt »
                </a>
              </p>
            )}
            <button
              className="button"
              type="button"
              onClick={() => setStatus("working")}
            >
              Re-compile
            </button>
            <button
              className="button button--action"
              type="button"
              onClick={previewResult}
            >
              View output »
            </button>
          </>
        )}
      </section>
    </main>
  );
}
