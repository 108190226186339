
export default function PreviewOutput({previewText}) {
  const copyOutput = () => {
      navigator.clipboard.writeText(previewText)
  }
  return (
    <aside className="column preview">
      <label className="inline-block">Output</label>
      <button type="button" className="button button--action" onClick={copyOutput}>Copy</button>
      <pre className="preview-output">{previewText}</pre>
    </aside>
  )
}